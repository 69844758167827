<template>
  <div>
    <div class="big_box" v-if="data">
      <div class="mrb15">
        <h3>乐刷支付</h3>
      </div>
      <div class="flex mrr30 top_box" v-if="dataCode">
        <div class="left_box">
          <div class="mrb15" v-if="data.shop">
            <div class="flex size16 mrb15">
              <div class="mrr8">终端id:</div>
              <div class="content-color">{{ data.shop.terminal_id}}</div>
            </div>

            <div class="flex size16 mrb15">
              <div class="mrr8">乐刷密钥:</div>
              <div class="content-color">{{ data.shop.saobei_token}}</div>
            </div>

            <div class="flex size16 mrb15">
              <div class="mrr8">商户号:</div>
              <div class="content-color">{{data.shop.merchant_no}}</div>
            </div>

            <!-- <div class="flex size16 mrb15">
              <div class="mrr8">商户状态：</div>
              <div>{{FilterState(data.merchant_status)}}</div>
            </div> -->

            <div class="flex size16 mrb15">
              <div class="mrr8">乐刷开关：</div>
              <!-- {{data.shop.saobei_enable}} -->
              <div>
                <el-switch v-model="data.shop.saobei_enable" @change='confirm(data)' active-color="#13ce66" inactive-color="#ff4949" :active-value='1' :inactive-value='0'>
                </el-switch>
              </div>
            </div>

            <el-row>
              <el-button type="primary" size="mini" @click="modification(data)">修 改</el-button>
            </el-row>
          </div>

          <div class="flex size16 mrb15" v-if="data">
            <div class="mrr8">上次提交时间:</div>
            <div class="content-color">{{ data.updated_at}}</div>
          </div>

          <!-- <div class="flex size16 mrb15">
            <div class="mrr8">状态：</div>
            <div>{{ data.return_msg }}</div>
          </div> -->

          <div class="flex size16 mrb15">
            <div class="mrr8">商户名称:</div>
            <div class="content-color">{{ data.merchantName}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">商户简称:</div>
            <div class="content-color">{{ data.merchantShortName}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">MCC类别:</div>
            <div class="content-color">{{ data.mccCode}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">省市区:</div>
            <div class="content-color">{{ data.provinceCode}}{{data.cityCode}}{{data.areaCode}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">详细地址:</div>
            <div class="content-color">{{ data.address}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">商户联系人:</div>
            <div class="content-color">{{ data.name}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">商户联系人电话:</div>
            <div class="content-color">{{ data.mobile}}</div>
          </div>
          <div class="flex size16 mrb15">
            <div class="mrr8">结算人身份证号码:</div>
            <div class="content-color">{{ data.idCardNo}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">持卡人:</div>
            <div class="content-color">{{ data.holder}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">入账银行卡:</div>
            <div class="content-color">{{ data.bankCardNo}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">法人代表名称:</div>
            <div class="content-color">{{ data.legalName}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">结算账户开户行:</div>
            <div class="content-color">{{ data.bank_name}}</div>
          </div>

          <div class="flex size16 mrb15">
            <div class="mrr8">支付费率:</div>
            <div class="content-color">{{ data.rate_code}}</div>
          </div>
        </div>

        <div class="right_box">
          <div class="flex">
            <div class="mrr15">状态：</div>
            <div>
              <div>{{ FilterCodeState( dataCode.authentication_status)}}</div>
            </div>
          </div>
          <div class="flex flex-column" v-if="dataCode.qrcode_data">
            <div class="mrr15">认证二维码：</div>
            <div class="qr-code"><img :src="dataCode.qrcode_data " alt=""></div>
          </div>

          <div v-if="dataCode.qrcode_data" class="refresh-box">

            <el-button type="primary" @click="getList();">刷 新</el-button>

          </div>
          <div v-if="dataCode.reject_reason">
            <div>驳回原因：</div>
            <div>{{ dataCode.reject_reason }}</div>
          </div>
        </div>
      </div>

      <el-row>
        <el-button type="primary" v-if="saobei_enable == 0 && data.merchant_status !== '05'" @click="openAccount">填写开户资料</el-button>
      </el-row>
    </div>

    <el-dialog title="修改信息" :visible.sync="dialogVisible" width="35%">
      <div>
        <el-form label-width="90px">
          <el-form-item label="终端id:">
            <el-input v-model="popoverValue.shop.terminal_id"></el-input>
          </el-form-item>
          <el-form-item label="乐刷密钥:">
            <el-input v-model="popoverValue.shop.saobei_token"></el-input>
          </el-form-item>
          <el-form-item label="乐刷开关：" prop="region">
            <el-select v-model="popoverValue.shop.saobei_enable" placeholder="请选择商户状态">
              <el-option label="开" :value="1"></el-option>
              <el-option label="关" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm(popoverValue)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      dataCode: {},
      shop_id: "",
      saobei_enable: "",
      data: null,
      popoverValue: {
        shop: {},
      },
      dialogVisible: false,
    };
  },

  created() {
    this.shop_id = this.$route.query.id;
    this.getList();
  },
  mounted() {},
  computed: {},

  methods: {
    confirm(row) {},
    modification(row) {
      // console.log("修改的表单值", row);
      // this.popoverValue = row;
      this.popoverValue = row;
      this.dialogVisible = true;
    },
    FilterState(row) {
      // console.log("row", row);
      const type = {
        "01": "审核通过,但未签署电子协议",
        "02": "审核驳回",
        "03": "审核中",
        "05": "审核通过且已签署电子协议",
      };
      console.log("返回的type", type[row]);
      return type[row];
    },
    FilterCodeState(row) {
      // console.log("row", row);
      const type = {
        0: "未申请",
        1: "审核中",
        2: "待联系人确认",
        3: "待法人确认",
        4: "审核通过(待授权)",
        5: "已授权",
        6: "已驳回 ",
      };
      // console.log("返回的type", type[row]);
      return type[row];
    },
    openAccount() {
      this.$router.push({
        path: "/pay/ShopAccount",
        query: {},
      });
    },

    async getList() {
      const { data } = await this.$Api2.leshuaInfo();

      console.log("乐刷支付", data);

      this.data = data;
    },
  },
  getCode() {},
};
</script>

<style lang='scss' scoped>
.big_box {
  background-color: #fff;
  padding: 20px;
}

.qr-code {
  width: 250px;
  height: 250px;
  img {
    width: 100%;
    height: 100%;
  }
}
.refresh-box {
  margin-top: 20px;
}
@media screen and (max-width: 500px) {
  .top_box {
    flex-direction: column;
  }

  .qr-code {
    width: 250px;
    height: 250px;
  }
}
</style>
